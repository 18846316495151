import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store/types';
import {adapter} from '@/store/adapter';
import {
  CaoComplianceDetail,
  InlenerCao,
  InlenerCaoDetail,
  InlenerDetails,
  ToggleInlenerFavorite,
} from '@/store/inleners/types';
import {SalaryTable} from '@/interfaces/salaryTable';

export const actions: ActionTree<{}, RootState> = {
  inviteInlenerUser(store: ActionContext<{}, RootState>, payload:
    { email: string }): Promise<Array<{ sent_at: string; sent_to_email: string }>> {
    return adapter.postRequest('/inlener/inlener-invite/', payload, true);
  },

  inlenerDetails(
    store: ActionContext<{}, RootState>,
    subscription_id: number,
  ): Promise<InlenerDetails> {
    return adapter.getRequest(`/inlener/inlener-detail/${subscription_id}/`, {}, true);
  },

  inlenerInvite(
    store: ActionContext<{}, RootState>,
    email: string,
  ): Promise<InlenerDetails> {
    return adapter.getRequest(`/inlener/inlener-invite/${email}/`, {}, true);
  },

  inlenerList(store, payload): Promise<Array<{ sent_at: string; sent_to_email: string }>> {
    return adapter.getRequest('/inlener/inlener-list/', payload, true);
  },

  inlenerCaos(
    store: ActionContext<{}, RootState>,
    payload: { page: number, contains: string, only_user: boolean, inlener_subscription_id: number },
  ): Promise<{ caos: InlenerCao[], page: number }> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/inleners-cao-uitzender-list/${payload.inlener_subscription_id}/`, payload, true)
        .then((response: { caos: InlenerCao[], page: number }) => {
          resolve(response);
        })
        .catch(reject);
    });
  },

  caoCompliances(
    store: ActionContext<{}, RootState>,
    payload: { page: number, contains: string, only_user: boolean, inlener_subscription_id: number },
  ): Promise<{ caos: InlenerCao[], page: number }> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/cao-compliance-uitzender-list/${payload.inlener_subscription_id}/`, payload, true)
        .then((response: { caos: InlenerCao[], page: number }) => {
          resolve(response);
        })
        .catch(reject);
    });
  },

  caoComplianceDetail(store: ActionContext<{}, RootState>, caoId: string): Promise<CaoComplianceDetail> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inleners/caos/${caoId}`, {}, true)
        .then((response: CaoComplianceDetail) => {
          resolve(response);
        }).catch(() => reject());
    });
  },

  salaryTables(store: ActionContext<{}, RootState>, caoId: string): Promise<SalaryTable[]> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/inleners/salary-tables/', {caoId}, true)
        .then((response: { [group_number_workweek: string]: SalaryTable[] }) => {
          resolve(
            Object.values(response).reduce(
              (acc, curr) => acc.concat(curr),
              [],
            ),
          );
        }).catch(() => reject());
    });
  },

  werksoorts(store: ActionContext<{}, RootState>, caoId: string): Promise<Array<{}>> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/inleners/werksoorts/', {caoId}, true)
        .then((response: Array<{}>) => {
          resolve(response);
        }).catch(() => reject());
    });
  },

  inlenerCaoDetail(store: ActionContext<{}, RootState>, inlenerCaoId: number): Promise<InlenerCaoDetail[]> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/inlener/inleners-cao-detail/', {inlenerCaoId: inlenerCaoId.toString()}, true)
        .then((response: InlenerCaoDetail[]) => {
          resolve(response);
        }).catch(() => reject());
    });
  },

  inlenerCaoVersions(store: ActionContext<{}, RootState>, inlenerCaoGuid: string): Promise<InlenerCao[]> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/inleners-cao-uitzender-versions/${inlenerCaoGuid}/`, {}, true)
        .then((response: InlenerCao[]) => {
          resolve(response);
        }).catch(() => reject());
    });
  },

  toggleInlenerCaos(
    store: ActionContext<{}, RootState>,
    params: { inlener_cao_id: number, api: string, tool: string }):
    Promise<any> {
    return adapter.putRequest('/inlener/cao-toggle/', params, store.rootGetters['account/isLoggedIn']);
  },

  toggleCaoCompliances(
    store: ActionContext<{}, RootState>,
    params: { cao_compliance_id: number, api: string }):
    Promise<any> {
    return adapter.putRequest('/inlener/cao-compliance-toggle/', params, store.rootGetters['account/isLoggedIn']);
  },

  checkInlenerCao(
    store: ActionContext<{}, RootState>,
    params: { inlener_cao_id: string }):
    Promise<any> {
    return adapter.getRequest('/inlener/cao-check/', params, store.rootGetters['account/isLoggedIn']);
  },

  toggleFavoriteInlener(
    store: ActionContext<{}, RootState>,
    params: { inlenerData: ToggleInlenerFavorite }):
    Promise<{ favorite: boolean }> {
    return adapter.putRequest('/inlener/toggle-favorite/', params, store.rootGetters['account/isLoggedIn']);
  },
};
